import React from "react";
import loadable from "@loadable/component";
import processAnimation from "@images/dev-ops/devops-process-animation.gif";
import devOpsProcess1 from "@images/dev-ops/devops-process1.svg";
import devOpsProcess2 from "@images/dev-ops/devops-process2.svg";
import devOpsProcess3 from "@images/dev-ops/devops-process3.svg";
import devOpsProcess4 from "@images/dev-ops/devops-process4.svg";
import devOpsProcess5 from "@images/dev-ops/devops-process5.svg";
import devOpsProcess6 from "@images/dev-ops/devops-process6.svg";
import devOpsProcess7 from "@images/dev-ops/devops-process7.svg";
import devOpsProcess8 from "@images/dev-ops/devops-process8.svg";
import devOpsProcess_mobile1 from "@images/dev-ops/devops-process-mobile1.svg";
import devOpsProcess_mobile2 from "@images/dev-ops/devops-process-mobile2.svg";
import devOpsProcess_mobile3 from "@images/dev-ops/devops-process-mobile3.svg";
import devOpsProcess_mobile4 from "@images/dev-ops/devops-process-mobile4.svg";
import devOpsProcess_mobile5 from "@images/dev-ops/devops-process-mobile5.svg";
import devOpsProcess_mobile6 from "@images/dev-ops/devops-process-mobile6.svg";
import devOpsProcess_mobile7 from "@images/dev-ops/devops-process-mobile7.svg";
import devOpsProcess_mobile8 from "@images/dev-ops/devops-process-mobile8.svg";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { ProcessListSectionContainer } from "./ProcessSection.style";

const ProcessListSection = loadable(() =>
    import("@common/CommonSections/ProcessListSection/ProcessListSection")
);
const DesktopProcessListSection = loadable(() =>
    import(
        "@common/CommonSections/DesktopProcessListSection/DesktopProcessListSection"
    )
);

const ProcessItemsInfo = [
    {
        heading: "1. Analysis",
        description: `
            No process can start without research and analysis. First and foremost, 
            we will study the product and security requirements and then look for 
            suitable infrastructure solutions.
        `,
        image: [devOpsProcess1, devOpsProcess_mobile1],
    },
    {
        heading: "2. Infrastructure Planning",
        description: `
            A closer examination of non-technical and technical requirements 
            allows us to plan development, testing, stage, and production.
        `,
        image: [devOpsProcess2, devOpsProcess_mobile2],
    },
    {
        heading: "3. Infrastructure Implementation",
        description: `
            This stage implies the development, test, and stage environments’ deployment.
        `,
        image: [devOpsProcess3, devOpsProcess_mobile3],
    },
    {
        heading: "4. CI/CD Pipeline Construction",
        description: `
            At this point, we are ready to introduce continuous integration and a continuous delivery pipeline. 
            Thanks to automation and monitoring, all phases of the software development life cycle are improved.
        `,
        image: [devOpsProcess4, devOpsProcess_mobile4],
    },
    {
        heading: "5. Back-up Systems Planning and Construction",
        description: `
            This is an essential activity for any business — data, state, and files of 
            your project are copied and stored securely in case something goes wrong.
        `,
        image: [devOpsProcess5, devOpsProcess_mobile5],
    },
    {
        heading: "6. Testing Infrastructure",
        description: `
            CI/CD pipeline affects the testing process as well. With DevOps, testing 
            spans every stage of SDLC thus ensuring fewer bugs and faster development.
        `,
        image: [devOpsProcess6, devOpsProcess_mobile6],
    },
    {
        heading: "7. Release",
        description: `
            As the last checks were made, at this stage, everyone involved in development 
            confirms that the project is ready to be deployed into the production environment.
        `,
        image: [devOpsProcess7, devOpsProcess_mobile7],
    },
    {
        heading: "8. Support and Maintenance",
        description: `
            Constant monitoring allows one to keep the finger on the pulse of everything 
            that happens with the project thus making quick decisions, corrections, 
            and improvements if necessary.
        `,
        image: [devOpsProcess8, devOpsProcess_mobile8],
    },
];

const ProcessSection = () => {
    const { isDesktop, isMobile } = useWindowDimensions();

    return (
        <ProcessListSectionContainer>
            {!isDesktop ? (
                <ProcessListSection
                    ProcessItemsInfo={ProcessItemsInfo}
                    isMobile={isMobile}
                    heading="The stages of the development"
                    itemsQuantity={ProcessItemsInfo.length}
                    imagePosition={isMobile ? "" : "top"}
                />
            ) : (
                <DesktopProcessListSection
                    ProcessItemsInfo={ProcessItemsInfo}
                    gifAnimation={processAnimation}
                    isMobile={isMobile}
                    heading="The stages of the development"
                    itemsQuantity={ProcessItemsInfo.length}
                />
            )}
        </ProcessListSectionContainer>
    );
};

export default ProcessSection;
