import React from "react";
import loadable from "@loadable/component";
import processWeb1 from "@images/web-development/processWeb1.svg";
import processWeb2 from "@images/web-development/processWeb2.svg";
import processWeb3 from "@images/web-development/processWeb3.svg";
import processWeb4 from "@images/web-development/processWeb4.svg";
import processWeb5 from "@images/web-development/processWeb5.svg";
import processWeb6 from "@images/web-development/processWeb6.svg";
import processWeb_mobile1 from "@images/web-development/processWeb-mobile1.svg";
import processWeb_mobile2 from "@images/web-development/processWeb-mobile2.svg";
import processWeb_mobile3 from "@images/web-development/processWeb-mobile3.svg";
import processWeb_mobile4 from "@images/web-development/processWeb-mobile4.svg";
import processWeb_mobile5 from "@images/web-development/processWeb-mobile5.svg";
import processWeb_mobile6 from "@images/web-development/processWeb-mobile6.svg";
import processAnimation from "@images/web-development/web-process-animation.gif";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { ProcessSectionStyle } from "../../../Styles/ProcessSectionStyle";

const ProcessListSection = loadable(() =>
    import("@common/CommonSections/ProcessListSection/ProcessListSection")
);
const DesktopProcessListSection = loadable(() =>
    import(
        "@common/CommonSections/DesktopProcessListSection/DesktopProcessListSection"
    )
);

const ProcessItemsInfo = [
    {
        heading: "1. Product Discovery and Planning",
        description: `
            This is a crucial stage of web app development during which our team will 
            conduct thorough research and analysis of your idea. As a result, you will 
            get a clear understanding of the development process, an estimation of time and budget, 
            and a list of clear non-tech and tech requirements. Hire web product managers 
            with Impressit to nail the discovery and planning phase!
        `,
        image: [processWeb1, processWeb_mobile1],
    },
    {
        heading: "2. Design",
        description: `
            Considering the newest trends in UX and UI, we will present you with the design that
            will go in line with your brand identity as well as assure effortless and pleasant usage
            of your product.
        `,
        image: [processWeb2, processWeb_mobile2],
        isShifted: true,
    },
    {
        heading: "3. Development",
        description: `
            At this stage, using the results of the previous web app development phases, we turn your idea 
            into a real product. It includes the development of the back end and front end as well
            as database configuration and custom APIs. Moreover, by adopting DevOps principles and practices, 
            we ensure faster and better releases, increased productivity, and more flexibility.
        `,
        image: [processWeb3, processWeb_mobile3],
    },
    {
        heading: "4. Testing",
        description: `
            To ensure that the end-user experience is excellent, our QA experts manually test the product. 
            It also helps to identify the possible security issues and avoid them.
        `,
        image: [processWeb4, processWeb_mobile4],
    },
    {
        heading: "5. Launch",
        description: `
            At this point, we are sure that the product runs smoothly, and we're ready to release it. 
            Of course, this would not be the first time you see it as during development as we run demos 
            for you to see the web application development progress.
        `,
        image: [processWeb5, processWeb_mobile5],
    },
    {
        heading: "6. Maintenance",
        description: `
            Two words: continuous improvement. We are ready to not only support your product as it is but
            also help you to reveal new opportunities and implement new features while your product wins the market.
        `,
        image: [processWeb6, processWeb_mobile6],
        isShifted: true,
    },
];

const ProcessSection = () => {
    const { isMobile, isDesktop } = useWindowDimensions();

    return (
        <>
            <ProcessSectionStyle />
            <div className="process-list__container">
                {!isDesktop ? (
                    <ProcessListSection
                        ProcessItemsInfo={ProcessItemsInfo}
                        isMobile={isMobile}
                        heading="The stages of the web application development"
                        itemsQuantity={ProcessItemsInfo.length}
                        imagePosition={isMobile ? "" : "top"}
                    />
                ) : (
                    <DesktopProcessListSection
                        ProcessItemsInfo={ProcessItemsInfo}
                        gifAnimation={processAnimation}
                        isMobile={isMobile}
                        heading="The stages of the web application development"
                        itemsQuantity={ProcessItemsInfo.length}
                    />
                )}
            </div>
        </>
    );
};

export default ProcessSection;
