import styled from "styled-components";
import { TABLET_MAX_WIDTH } from "@constants";

export const ProcessListSectionContainer = styled.div`
    & .process-item {
        &__border-container {
            margin-right: 133px;
        }

        &__image7 svg {
            margin-top: -70px;
            margin-left: 60px;

            @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                margin-top: 0;
                margin-left: 0;
            }
        }
    }
`;
