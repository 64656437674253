import React from "react";
import loadable from "@loadable/component";
import processDiscovery1 from "@images/discovery-and-planning/processDiscovery1.svg";
import processDiscovery2 from "@images/discovery-and-planning/processDiscovery2.svg";
import processDiscovery3 from "@images/discovery-and-planning/processDiscovery3.svg";
import processDiscovery4 from "@images/discovery-and-planning/processDiscovery4.svg";
import processDiscovery5 from "@images/discovery-and-planning/processDiscovery5.svg";
import processDiscoveryMobile1 from "@images/discovery-and-planning/processDiscoveryMobile1.svg";
import processDiscoveryMobile2 from "@images/discovery-and-planning/processDiscoveryMobile2.svg";
import processDiscoveryMobile3 from "@images/discovery-and-planning/processDiscoveryMobile3.svg";
import processDiscoveryMobile4 from "@images/discovery-and-planning/processDiscoveryMobile4.svg";
import processDiscoveryMobile5 from "@images/discovery-and-planning/processDiscoveryMobile5.svg";
import processAnimation from "@images/discovery-and-planning/discovery-process-animation.gif";
import useWindowDimensions from "@hooks/useWindowDimensions";

const ProcessListSection = loadable(() =>
    import("@common/CommonSections/ProcessListSection/ProcessListSection")
);
const DesktopProcessListSection = loadable(() =>
    import(
        "@common/CommonSections/DesktopProcessListSection/DesktopProcessListSection"
    )
);

const ProcessItemsInfo = [
    {
        heading: "1. Validating Your Idea",
        description: `
            We conduct scrupulous research that includes studying the market trends, 
            your potential customers, and competitors as well as work closely with you 
            in order to understand what value you want to bring to the world with your product.
        `,
        image: [processDiscovery1, processDiscoveryMobile1],
    },
    {
        heading: "2. Identifying Challenges and Opportunities",
        description: `
            As a result of our research, we are able to show you which possible issues we’ve 
            revealed and which hidden opportunities we’ve noticed. That is why discovery phase
            services are particularly important for further work.
        `,
        image: [processDiscovery2, processDiscoveryMobile2],
    },
    {
        heading: "3. Setting the Requirements",
        description: `
            At this stage, we already have an understanding of what your product will look like. 
            Therefore, we are ready to set technical and non-technical requirements that would 
            be a foundation for the development
        `,
        image: [processDiscovery3, processDiscoveryMobile3],
    },
    {
        heading: "4. Preparing the Project Scope",
        description: `
            Project Scope will include the concept of the project, budget and time estimates, 
            team size and composition suggestions. Basically, it will be easier for you to hire 
            software development team as we will show you what and whom you need to create the project 
            and how much time and resources it will take.
        `,
        image: [processDiscovery4, processDiscoveryMobile4],
    },
    {
        heading: "5. Creating Documentation",
        description: `
            At the end of the discovery and planning phase, you will get a number of documents necessary 
            for further development. These documents would help the development team as well as your operations
            team to manage the product later
        `,
        image: [processDiscovery5, processDiscoveryMobile5],
    },
];

const ProcessSection = () => {
    const { isMobile, isDesktop } = useWindowDimensions();

    return (
        <div>
            {!isDesktop ? (
                <ProcessListSection
                    ProcessItemsInfo={ProcessItemsInfo}
                    heading="The process of product discovery"
                    itemsQuantity={ProcessItemsInfo.length}
                    isMobile={isMobile}
                />
            ) : (
                <DesktopProcessListSection
                    ProcessItemsInfo={ProcessItemsInfo}
                    gifAnimation={processAnimation}
                    isMobile={isMobile}
                    heading="The process of product discovery"
                    itemsQuantity={ProcessItemsInfo.length}
                    isFirstSection
                />
            )}
        </div>
    );
};

export default ProcessSection;
