import { createGlobalStyle } from "styled-components";
import { TABLET_MAX_WIDTH } from "@constants";

export const ProcessSectionStyle = createGlobalStyle`
    .process-list__container {
        & .process-item {
            &__border-container {
                margin-right: 8.3125rem;
            }
        }

        &-mobile {
            & .process-item {
                &__border-container1 {
                    margin-right: 0;
                }
        
                &__image {
                    &1 {
                        & svg {
                            margin-top: 2.1875rem;
                
                            @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                                margin-top: 0;
                            }
                        }
                    }
        
                    &2 svg {
                        margin-top: 2.1875rem;
                
                        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                            margin-top: 0;
                        }
                    }
        
                    &3 svg {
                        margin-top: 2.1875rem;
                
                        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                            margin-top: 0;
                        }
                    }
                }   
            }
        }
    }
`;
