import React from "react";
import loadable from "@loadable/component";
import processDesign1 from "@images/design-page/processDesign1.svg";
import processDesign2 from "@images/design-page/processDesign2.svg";
import processDesign3 from "@images/design-page/processDesign3.svg";
import processDesign4 from "@images/design-page/processDesign4.svg";
import processDesign5 from "@images/design-page/processDesign5.svg";
import processDesignMobile1 from "@images/design-page/processDesignMobile1.svg";
import processDesignMobile2 from "@images/design-page/processDesignMobile2.svg";
import processDesignMobile3 from "@images/design-page/processDesignMobile3.svg";
import processDesignMobile4 from "@images/design-page/processDesignMobile4.svg";
import processDesignMobile5 from "@images/design-page/processDesignMobile5.svg";
import processAnimation from "@images/design-page/design-process-animation.gif";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { ProcessListSectionContainer } from "./ProcessSection.style";

const ProcessListSection = loadable(() =>
    import("@common/CommonSections/ProcessListSection/ProcessListSection")
);
const DesktopProcessListSection = loadable(() =>
    import(
        "@common/CommonSections/DesktopProcessListSection/DesktopProcessListSection"
    )
);

const ProcessItemsInfo = [
    {
        heading: "1. Research and Analysis",
        description: `
            Our approach is, first and foremost, business-oriented, so we will begin with taking
            notes about your idea, business objectives, and current market needs. Then we will look
            into market trends, your competitors, your potential customers, and all the other useful data. 
            This will allow us to create user stories, user journeys, and user experience flows.
        `,
        image: [processDesign1, processDesignMobile1],
        isShifted: true,
    },
    {
        heading: "2. Concept & UX/UI Wireframes",
        description: `
            Applying the results of the previous research, we create the overall style concept for your 
            product that would later determine UI/UX design. Afterwards, the designer and developer create
            a rough wireframe with simple lines and shapes that would become a foundation for future design. 
            We gather your feedback and make respective revisions if necessary. 
        `,
        image: [processDesign2, processDesignMobile2],
        isShifted: true,
    },
    {
        heading: "3. Prototype",
        description: `
            The clickable prototype offers the final high fidelity look of the product. At this stage, 
            stakeholders and potential users may test the product and suggest changes and alterations. 
            This is an opportunity to polish the product and see if everything in it aligns with set business 
            goals before the team gets down to the most expensive part — the development.
        `,
        image: [processDesign3, processDesignMobile3],
        isShifted: true,
    },
    {
        heading: "4. Development",
        description: `
            At this point, we are ready to transfer the results of designers’ work into the 
            hands of skilled front end developers. They would apply all the visual elements to a real product.
        `,
        image: [processDesign4, processDesignMobile4],
    },
    {
        heading: "5. UI Testing",
        description: `
            As we put the user first when we develop the UI, at this stage, our QA professionals 
            test the interface to ensure it is responsive, intuitive, and works properly.
        `,
        image: [processDesign5, processDesignMobile5],
    },
];

const ProcessSection = () => {
    const { isMobile, isDesktop } = useWindowDimensions();

    return (
        <ProcessListSectionContainer>
            {!isDesktop ? (
                <ProcessListSection
                    ProcessItemsInfo={ProcessItemsInfo}
                    isMobile={isMobile}
                    heading="The UI/UX design process step-by-step"
                    itemsQuantity={ProcessItemsInfo.length}
                    imagePosition={isMobile ? "" : "top"}
                />
            ) : (
                <DesktopProcessListSection
                    ProcessItemsInfo={ProcessItemsInfo}
                    gifAnimation={processAnimation}
                    isMobile={isMobile}
                    heading="The UI/UX design process step-by-step"
                    itemsQuantity={ProcessItemsInfo.length}
                />
            )}
        </ProcessListSectionContainer>
    );
};

export default ProcessSection;
