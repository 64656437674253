import React from "react";
import loadable from "@loadable/component";
import processMobile1 from "@images/mobile-development/processMobile1.svg";
import processMobile2 from "@images/mobile-development/processMobile2.svg";
import processMobile3 from "@images/mobile-development/processMobile3.svg";
import processMobile4 from "@images/mobile-development/processMobile4.svg";
import processMobile5 from "@images/mobile-development/processMobile5.svg";
import processMobile6 from "@images/mobile-development/processMobile6.svg";
import processMobile_mobile1 from "@images/mobile-development/processMobile-mobile1.svg";
import processMobile_mobile2 from "@images/mobile-development/processMobile-mobile2.svg";
import processMobile_mobile3 from "@images/mobile-development/processMobile-mobile3.svg";
import processMobile_mobile4 from "@images/mobile-development/processMobile-mobile4.svg";
import processMobile_mobile5 from "@images/mobile-development/processMobile-mobile5.svg";
import processMobile_mobile6 from "@images/mobile-development/processMobile-mobile6.svg";
import processAnimation from "@images/mobile-development/process-animation.gif";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { ProcessSectionStyle } from "../../../Styles/ProcessSectionStyle";

const ProcessListSection = loadable(() =>
    import("@common/CommonSections/ProcessListSection/ProcessListSection")
);
const DesktopProcessListSection = loadable(() =>
    import(
        "@common/CommonSections/DesktopProcessListSection/DesktopProcessListSection"
    )
);

const ProcessItemsInfo = [
    {
        heading: "1. Discovery and planning",
        description: `
            Now it is time to dig deeper — at this stage we validate your idea, research 
            and analyze your competitors and potential audience, set the requirements, 
            and create the scope of work document. Basically, we prepare the basis for further
            development and support of your mobile application.
        `,
        image: [processMobile1, processMobile_mobile1],
    },
    {
        heading: "2. Design",
        description: `
            We are dedicated to your business’ success, and in mobile applications, design plays
            a crucial role. Our UX/UI designer will make sure that your app is not only attractive 
            but also user-friendly, intuitive, and responsive.
        `,
        image: [processMobile2, processMobile_mobile2],
        isShifted: true,
    },
    {
        heading: "3. Development",
        description: `
            Whether it’s an Android app or an iOS app, this stage usually consists of back end development, 
            API, and front end development. Because the Impressit team uses agile methodologies and offers only 
            the best mobile app developers for hire, you can be sure that your product will be delivered within 
            the set time frame and budget. 
        `,
        image: [processMobile3, processMobile_mobile3],
    },
    {
        heading: "4. Testing",
        description: `
            To ensure that your end-user experience is perfect, our QA team will conduct thorough testing of your application’s 
            performance, security, and functionality. We will also test it on different devices and operating systems to deliver 
            the best performance on the whole spectrum of mobile devices.
        `,
        image: [processMobile4, processMobile_mobile4],
    },
    {
        heading: "5. Launch",
        description: `
            Launching your mobile application differs from the web application’s launching. Play Store and AppStore have 
            certain policies that every app should follow. However, we will only ask you for developer account credentials 
            and will do the rest of the preparation work. After that, we will monitor usage and measure your app’s 
            performance and success.
        `,
        image: [processMobile5, processMobile_mobile5],
    },
    {
        heading: "6. Maintenance & support",
        description: `
            As you know, almost every app out there gets regular updates that take into account users’ feedback, improve
            technological performance, or introduce new features. We will stay by your side to be sure that your app 
            is at the forefront of the market.
        `,
        image: [processMobile6, processMobile_mobile6],
        isShifted: true,
    },
];

const ProcessSection = () => {
    const { isMobile, isDesktop } = useWindowDimensions();

    return (
        <>
            <ProcessSectionStyle />
            <div className="process-list__container process-list__container-mobile">
                {!isDesktop ? (
                    <ProcessListSection
                        ProcessItemsInfo={ProcessItemsInfo}
                        isMobile={isMobile}
                        heading="The stages of the development"
                        itemsQuantity={ProcessItemsInfo.length}
                        imagePosition={isMobile ? "" : "top"}
                    />
                ) : (
                    <DesktopProcessListSection
                        ProcessItemsInfo={ProcessItemsInfo}
                        gifAnimation={processAnimation}
                        isMobile={isMobile}
                        heading="The stages of the development"
                        itemsQuantity={ProcessItemsInfo.length}
                    />
                )}
            </div>
        </>
    );
};

export default ProcessSection;
